body{
    margin: 0;
    padding: 0;
    font-family: Helvetica, sans-serif;
    font-weight: lighter;
    font-size: 14px;
}

.Header {
    background: url('img/backgroundTop.png') repeat-x;
    height: 405px;
    width: 100%;
}

    .Header .Container {
        position: absolute;
        width: 1000px;
        left: 50%;
        margin-left: -500px;
    }

    .Header .Logo {
        position: absolute;
        margin-top: 85px;
        margin-left: 50px;
    }

    .Header .Photo {
        position: absolute;
        margin-left: 640px;
        margin-top: 93px;
        z-index: 11;
    }

    .Header .Tips {
        font-size: 12px;
        line-height: 17px;
        position: absolute;
        margin-left: 70px;
        margin-top: 230px;
        width: 350px;
        font-family: 'Montserrat', sans-serif;
    }

        .Header .Tips b {
            font-weight: bold;
            color: #a357a2;
            letter-spacing: 1px;
        }

        .Header .Tips .Facebook {
            display: table;
            background: #a357a2;
            padding: 10px 15px;
            border-radius: 5px;
            color: white;
        }
            .Header .Tips .Facebook .fa {
                padding: 5px 7px 5px 0;
                font-size: 10px;
                margin: 0;
                text-align: center;
                text-decoration: none;
            }

            .Header .Tips .Facebook:hover {
                opacity: 0.7;
                color: white;
                cursor: pointer;
            }

        .Header .Tips .fa-facebook {
            color: white;
        }

    .Header a:link, .Header a:visited, .Header a:hover, .Header a:active {
        text-decoration: none;
        color: inherit;
        border: none;
    }


.Menu {
    display: block;
}

    .Menu .Container {
        position: absolute;
        width: 1000px;
        left: 50%;
        margin-left: -500px;
        text-transform: uppercase;
        list-style: none;
    }

        .Menu .Container li {
            margin-top: 5px;
            float: left;
            margin-right: 15px;
            color: #a357a2;
            font-size: 14px;
            letter-spacing: 1px;
            padding: 5px;
            background-color: transparent;
            border: 1px solid transparent;
        }

            .Menu .Container li a:link, a:visited {
                text-decoration: none;
                color: #a357a2;
                border: none;
            }
            .Menu .Container li:hover a:link,.Menu .Container li:hover a:visited {
                color: white;
            }

            .Menu .Container li a:hover, a:active {
                text-decoration: none;
                color: white !important;
                border: none;
            }

            .Menu .Container li:hover {
                background-color: #a357a2;
                color: white !important;
                border-radius: 5px;
            }

.About {
    position: absolute;
    width: 900px;
    left: 50%;
    margin-left: -450px;
    margin-top: 100px;
    height: 180px;
}

    .About .Photo {
        float: left;
        margin: 20px 40px;
    }

    .About .Item {
        margin: 20px 30px;
    }

        .About .Item .Title {
            font-family: 'Merriweather', serif;
            font-weight: lighter;
            color: #a357a2;
            font-size: 22px;
        }

        .About .Item .Content {
            display: table;
            font-family: 'Montserrat', sans-serif;
            padding: 5px 0 0 5px;
            color: #565656;
            font-weight: lighter;
        }

.Treatments {
    position: absolute;
    height: 350px;
    width: 100%;
    margin-top: 350px;
    background-color: #fcf8fb;
}
    .Treatments .Container {
        position: absolute;
        width: 900px;
        left: 50%;
        margin-left: -450px;
        margin-top: 50px;
    }
        .Treatments .Container .Photo {
            float: right;
            margin: 40px 40px;
        }
        .Treatments .Container .Item {
            margin: 20px 30px;
        }

            .Treatments .Container .Item .Title {
                font-family: 'Merriweather', serif;
                font-weight: lighter;
                color: #a357a2;
                font-size: 22px;
            }

            .Treatments .Container .Item .Content {
                display: table;
                font-family: 'Montserrat', sans-serif;
                padding: 5px 0 0 5px;
                color: #565656;
                font-weight: lighter;
            }
            .Treatments .Container .Item .Content .ItemList{
                list-style: none;
                margin: 15px;
                padding: 0;
            }
                .Treatments .Container .Item .Content .ItemList li b{
                    font-weight: bold;
                    color: #a357a2;
                    letter-spacing: 1px;
                }
            .Treatments .Container .Item .Content .BulletList{
                list-style: none;
                margin: 0;
                padding: 0;
            }
                .Treatments .Container .Item .Content .BulletList li {
                    background-color: transparent;
                    padding: 0;
                    float: left;
                    width: 10px;
                    height: 10px;
                    margin: 5px;
                    border-radius: 50%;
                    border: 5px solid #a357a2;
                    cursor: pointer;
                }
                    .Treatments .Container .Item .Content .BulletList li.active {
                        border:5px solid #a357a2;
                        background-color: #a357a2;
                        cursor: default;
                    }

.Products {
    position: absolute;
    height: 300px;
    width: 100%;
    margin-top: 700px;
    background-color: white;
}
    .Products .Container {
        position: absolute;
        width: 900px;
        left: 50%;
        margin-left: -450px;
        margin-top: 50px;
    }
    .Products .Container .Photo {
        float: left;
        margin: 40px 40px;
    }
    .Products .Container .Item {
        margin: 20px 30px;
    }

        .Products .Container .Item .Title {
            font-family: 'Merriweather', serif;
            font-weight: lighter;
            color: #a357a2;
            font-size: 22px;
        }

        .Products .Container .Item .Content {
            display: table;
            font-family: 'Montserrat', sans-serif;
            padding: 5px 0 0 5px;
            color: #565656;
            font-weight: lighter;
        }

.Contact {
    position: absolute;
    height: 300px;
    width: 100%;
    margin-top: 1000px;
    background-color: #fcf8fb;
}

    .Contact .Container {
        position: absolute;
        width: 900px;
        left: 50%;
        margin-left: -450px;
        margin-top: 50px;
    }
    .Contact .Container .Item {
        margin: 20px 30px;
    }

        .Contact .Container .Item .Title {
            font-family: 'Merriweather', serif;
            font-weight: lighter;
            color: #a357a2;
            font-size: 22px;
        }

        .Contact .Container .Item .Content {
            display: table;
            font-family: 'Montserrat', sans-serif;
            padding: 5px 0 0 5px;
            color: #565656;
            font-weight: lighter;
        }
    .Contact a:link,
    .Contact a:visited{
        text-decoration: none;
        color: #a357a2;
    }

    .Contact a:hover,
    .Contact a:active{
        text-decoration: underline;
        color: #a357a2;
    }
@media only screen and (max-width: 1000px){
    /* Mobile styling */
    .Header {
        height: 250px;
    }
    .Header .Container{
        position: relative;
        width: 100%;
        left: 0;
        margin-left: 0;
        height: 200px;
    }
        .Header .Logo {
            display: block;
            position: absolute;
            width: 230px;
            top: 0;
            left: 50%;
            margin-left: -115px;

        }
        .Header .Photo {
            display: none;
        }
        .Header .Tips {
            display: none;
        }
    .About {
        position: relative;
        width: 100%;
        left: 0;
        margin-left: 0;
        margin-top: 25px;
        padding-bottom: 20px;
        height: auto;
    }

        .About .Photo {
            display: none;
        }
    .Treatments {
        position: relative;
        display: block;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
        margin-top: 0;
        background-color: #fcf8fb;
        height: auto;
    }
    .Treatments .Container {
        position: relative;
        width: 100%;
        left: 0;
        margin-left: 0;
        margin-top: 0;
    }
    .Treatments .Container .Photo {
        display: none;
        float: right;
        margin: 40px 40px;
    }
    .Treatments .Container .Item {
        margin: 20px 30px;
    }

    .Treatments .Container .Item .Title {
        font-family: 'Merriweather', serif;
        font-weight: lighter;
        color: #a357a2;
        font-size: 22px;
    }

    .Treatments .Container .Item .Content {
        display: table;
        font-family: 'Montserrat', sans-serif;
        padding: 5px 0 0 5px;
        color: #565656;
        font-weight: lighter;
    }
    .Treatments .Container .Item .Content .ItemList{
        list-style: none;
        margin: 30px 15px 15px 15px;
        padding: 0;
    }

    .Products {
        position: relative;
        height: auto;
        width: 100%;
        margin-top: 0;
        background-color: white;
        padding-bottom: 20px;
    }
    .Products .Container {
        position: relative;
        width: 100%;
        left: 0;
        margin-left: 0;
        margin-top: 50px;
    }
    .Products .Container .Photo {
        display: none;
    }
    .Contact {
        position: relative;
        height: 300px;
        width: 100%;
        margin-top: 0;
        background-color: #fcf8fb;
    }
    .Contact .Container {
        position: absolute;
        width: 100%;
        left: 0;
        margin-left: 0;
        margin-top: 50px;
    }

}
