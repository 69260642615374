.under_construction{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
}
    .under_construction a:link, .under_construction a:active{
        text-decoration: none;
        color: #a357a2;
    }
    .under_construction a:hover, .under_construction a:visited{
        text-decoration: none;
        color: #a357a2;
        border-bottom: 1px dashed #a357a2;
    }
    .under_construction .modal{
        position: fixed;
        top: 50%;
        left: 50%;
        height: 200px;
        width: 300px;
        margin-top: -100px;
        margin-left: -150px;
        text-align: center;
    }
        .under_construction .modal .logo{
            margin-bottom: 15px;
        }
        .under_construction .modal .title{
            font-weight: bold;
            padding: 5px;
            background-color: #a357a2;
            color: #fff;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        .under_construction .modal .content{
            padding: 10px;
            border-left: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            border-right: 1px solid #ddd;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }